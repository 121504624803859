var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"mline"}),_c('div',{staticClass:"hnews"},[_c('div',{staticClass:"hnews-box left"},[_vm._m(0),_c('div',{staticClass:"hnews-bd mnews-bd"},[_c('ul',{staticClass:"mnewslist"},_vm._l((_vm.activityList),function(item){return _c('li',{key:item.id},[_c('a',{on:{"click":function($event){return _vm.toDetail('article', item)}}},[_c('div',{staticClass:"img"},[_c('img',{staticStyle:{"height":"80px","background":"rgba(0,0,0,0.03)"},attrs:{"src":item.cover}})]),_c('div',{staticClass:"txts"},[_c('h3',{staticClass:"tit"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(item.articleAbstract)+" ")]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(item.releaseTime))])])])])}),0)]),_c('div',{staticClass:"hnews-ft"},[_c('a',{staticClass:"mbtn",on:{"click":function($event){return _vm.toRouter('information', {
              type: 3,
              title: '热点资讯',
              en: 'Popular informationes',
            })}}},[_vm._v("更多资讯")])])]),_c('div',{staticClass:"hnews-box right"},[_vm._m(1),_c('div',{staticClass:"hnews-bd mnews-bd"},[_c('ul',{staticClass:"mnewslist"},_vm._l((_vm.broadcast),function(item){return _c('li',{key:item.id},[_c('a',{on:{"click":function($event){return _vm.toDetail('article', item)}}},[_c('div',{staticClass:"img"},[_c('img',{staticStyle:{"height":"80px","background":"rgba(0,0,0,0.03)"},attrs:{"src":item.cover}})]),_c('div',{staticClass:"txts"},[_c('h3',{staticClass:"tit"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"info"},[_vm._v(" "+_vm._s(item.articleAbstract)+" ")]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(item.releaseTime))])])])])}),0)]),_c('div',{staticClass:"hnews-ft"},[_c('a',{staticClass:"mbtn",on:{"click":function($event){return _vm.toRouter('information', {
              type: 3,
              title: '热点直播',
              en: 'NEWS',
            })}}},[_vm._v("更多直播")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mtit hnews-hd"},[_c('h2',{staticClass:"cn"},[_vm._v("热点资讯")]),_c('div',{staticClass:"en"},[_vm._v("Popular informationes")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mtit hnews-hd"},[_c('h2',{staticClass:"cn"},[_vm._v("热点直播")]),_c('div',{staticClass:"en"},[_vm._v("Popular live streaming")])])}]

export { render, staticRenderFns }