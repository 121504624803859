<template>
  <div class="wrapper">
    <div class="mline"></div>
    <div class="hnews">
      <div class="hnews-box left">
        <div class="mtit hnews-hd">
          <h2 class="cn">热点资讯</h2>
          <div class="en">Popular informationes</div>
        </div>
        <div class="hnews-bd mnews-bd">
          <ul class="mnewslist">
            <li v-for="item in activityList"
                :key="item.id">
              <a @click="toDetail('article', item)">
                <div class="img">
                  <img :src="item.cover"
                       style="height:80px;background:rgba(0,0,0,0.03);" />
                </div>
                <div class="txts">
                  <h3 class="tit">
                    {{ item.title }}
                  </h3>
                  <div class="info">
                    {{ item.articleAbstract }}
                  </div>
                  <div class="time">{{ item.releaseTime }}</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="hnews-ft">
          <a @click="
              toRouter('information', {
                type: 3,
                title: '热点资讯',
                en: 'Popular informationes',
              })
            "
             class="mbtn">更多资讯</a>
        </div>
      </div>
      <div class="hnews-box right">
        <div class="mtit hnews-hd">
          <h2 class="cn">热点直播</h2>
          <div class="en">Popular live streaming</div>
        </div>
        <div class="hnews-bd mnews-bd">
          <ul class="mnewslist">
            <li v-for="item in broadcast"
                :key="item.id">
              <a @click="toDetail('article', item)">
                <div class="img">
                  <img :src="item.cover"
                       style="height:80px;background:rgba(0,0,0,0.03);" />
                </div>
                <div class="txts">
                  <h3 class="tit">
                    {{ item.title }}
                  </h3>
                  <div class="info">
                    {{ item.articleAbstract }}
                  </div>
                  <div class="time">{{ item.releaseTime }}</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="hnews-ft">
          <a @click="
              toRouter('information', {
                type: 3,
                title: '热点直播',
                en: 'NEWS',
              })
            "
             class="mbtn">更多直播</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data () {
    return {
      broadcast: [],
      activityList: [],
    };
  },
  mounted () {
    this.getTabArticleList(3);
    this.getTabArticleList(6);
  },
  methods: {
    toRouter (routerName, item) {
      let query = {};
      query = {
        type: item.type,
        title: item.title,
        en: item.en,
      };

      this.newRouter("/" + routerName, query);
    },
    toDetail (path, data) {
      newRouter("/" + path, {
        id: data.id,
      });
    },
    getTabArticleList (type) {
      this.api
        .getTabArticleList({ pageNo: 1, pageSize: 5, type: type })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          if (type == 6) {
            this.broadcast = records;
          } else {
            this.activityList = records;
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.hnews-ft .mbtn {
  margin-bottom: 20px;
}
</style>
