<template>
  <div class="mrow mnewsbox">
    <div class="mimgs">
      <div class="mtit1">
        <h2 class="cn">园区图集</h2>
        <div class="en">Park Atlas</div>
        <div class="line"></div>
      </div>
      <div class="mimgs-tabhd">
        <ul>
          <li @click="toTaglet(0)"
              :class="activeTab == 0 ? 'active' : ''">
            <a href="javascript:;">
              <i class="icon">
                <img class="icon01"
                     src="../../../assets/images/icon-cgfw.png" />
                <img class="icon02"
                     src="../../../assets/images/icon-cgfw-w.png" />
              </i>
              参观访问
            </a>
          </li>
          <li @click="toTaglet(1)"
              :class="activeTab == 1 ? 'active' : ''">
            <a href="javascript:;">
              <i class="icon">
                <img class="icon01"
                     src="../../../assets/images/icon-hdtj.png" />
                <img class="icon02"
                     src="../../../assets/images/icon-hdtj-w.png" />
              </i>
              精彩活动
            </a>
          </li>
        </ul>
      </div>
      <div class="mimgs-tabbd">
        <div class="swiper-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide"
                v-for="item in visitList"
                :key="item.id">
              <div class="mimgs-box"
                   style="width:100%;"
                   @click="toDetail('article', item)">
                <div class="img"
                     style="width:100%;height:330px;overflow:hidden;position: relative;">
                  <img :src="item.cover"
                       style="width:100%;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);" />
                </div>
                <h3>
                  {{ item.title }}
                </h3>
              </div>
            </li>
          </ul>
        </div>
        <div class="mimgs-btn">
          <div class="swiper-button-prev mimgs-prev"></div>
          <div class="swiper-button-next mimgs-next"></div>
        </div>
      </div>
      <div class="mimgs-tabbd gnone">
        <div class="swiper-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide"
                v-for="item in activityList"
                :key="item.id">
              <div class="mimgs-box"
                   style="width:100%;"
                   @click="toDetail('article', item)">
                <div class="img"
                     style="width:100%;height:330px;overflow:hidden;position: relative;">
                  <img :src="item.cover"
                       style="width:100%;position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);" />
                </div>
                <h3>
                  {{ item.title }}
                </h3>
              </div>
            </li>
          </ul>
        </div>
        <div class="mimgs-btn">
          <div class="swiper-button-prev mimgs-prev"></div>
          <div class="swiper-button-next mimgs-next"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
import $ from "@/libs/js/jquery-1.10.1.min";
import Swiper from "@/libs/js/swiper.min";
// import "swiper/dist/css/swiper.min.css";
export default {
  data () {
    return {
      list: [],
      visitList: [],
      activityList: [],
      activeTab: 0,
    };
  },
  mounted () {
    this.getTabArticleList(2);
    this.getTabArticleList(5);
  },
  methods: {
    toDetail (path, data) {
      newRouter("/" + path, {
        id: data.id,
      });
    },
    initSwiper () {
      new Swiper(".mimgs-tabbd .swiper-container", {
        slidesPerView: 2,
        loop: true,
        autoplayDisableOnInteraction: false,
        autoplay: 4000,
        centeredSlides: true,
        nextButton: ".mimgs-next",
        prevButton: ".mimgs-prev",
        breakpoints: {
          1000: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      });
    },
    toTaglet (idex) {
      $(".mimgs-tabbd").hide();
      $(".mimgs-tabbd").eq(idex).fadeIn();

      this.activeTab = idex;
      this.initSwiper();
    },
    getTabArticleList (type) {
      this.api
        .getTabArticleList({ pageNo: 1, pageSize: 50, type: type })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          if (type == 5) {
            this.visitList = records;
          } else {
            this.activityList = records;
          }
          setTimeout(() => {
            this.initSwiper();
          }, 500);
        });
    },
  },
};
</script>
<style scoped lang="scss"></style>
