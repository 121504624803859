<template>
  <div class="mrow mimport">
    <div class="wrapper">
      <div class="mtit1">
        <h2 class="cn">党建要闻</h2>
        <div class="en">Important news</div>
        <div class="line"></div>
      </div>
      <div class="mimport-box">
        <div class="mimport-imgs swiper-container">
          <ul class="swiper-wrapper">
            <li class="swiper-slide"
                v-for="item in broadcast"
                :key="item.id"
                @click="toDetail('article', item)">
                <a>
              <img class="img"
                   :src="item.cover" />
                </a>
            </li>
          </ul>
          <div class="swiper-pagination"></div>
        </div>
        <div class="mimport-list">
          <ul class="mnewslist1">
            <li v-for="item in broadcast"
                :key="item.id">
              <a @click="toDetail('article', item)">
                <h3 class="tit">
                  {{ item.title }}
                </h3>
                <span class="time">{{ item.releaseTime }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="mimport-ft">
        <a @click="
            toRouter('information', {
              type: 4,
              title: '党建要闻',
              en: 'NEWS OF THE PARTY',
            })
          "
           class="mbtn white">更多要闻</a>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "@/libs/js/swiper.min";
import { newRouter } from "@/libs/comm";
export default {
  data () {
    return {
      broadcast: [],
    };
  },
  mounted () {
    this.getTabArticleList();
  },
  methods: {
    toRouter (routerName, item) {
      let query = {};
      query = {
        type: item.type,
        title: item.title,
        en: item.en,
      };

      this.newRouter("/" + routerName, query);
    },
    toDetail (path, data) {
      newRouter("/" + path, {
        id: data.id,
      });
    },
    initSwiper () {
      new Swiper(".mimport-imgs", {
        pagination: ".swiper-pagination",
        paginationClickable: true,
        autoplayDisableOnInteraction: false,
        autoplay: 5000,
        loop: true,
      });
    },
    getTabArticleList () {
      this.api
        .getTabArticleList({ pageNo: 1, pageSize: 10, type: 4 })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          this.broadcast = records;
          setTimeout(() => {
            this.initSwiper();
          }, 500);
        });
    },
  },
};
</script>
<style scoped lang="scss"></style>
