<template>
  <div class="mbanner">
    <div class="wrapper">
      <div class="mbanner-tit">
        <h2>中国石家庄人力资源服务产业园</h2>
        <h3>服务城市未来 服务区域发展</h3>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.mbanner {
  background-image: url(../../../assets/images/mbanner02.jpg);
}
</style>
