<template>
  <div class="el_activitynews">
    <banner />
    <atlas />
    <hotspot />
    <partyBuilding />
  </div>
</template>

<script>
import banner from "./banner.vue";
import atlas from "./atlas.vue";
import hotspot from "./hotspot.vue";
import partyBuilding from "./partyBuilding.vue";
export default {
  data() {
    return {
      msg: "el_activitynews",
    };
  },
  components: {
    banner,
    atlas,
    hotspot,
    partyBuilding,
    // sidebar,
  },
  created() {},
  methods: {},
};
</script>
<style scoped lang="scss"></style>
